import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import LandingPage from '@/components/app/Landing/LandingPage';
import ContainedAppLayout from '@/components/app/Layout/ContainedAppLayout';
import ProfilePage from '@/components/app/Profile/ProfilePage';
import CompleteRegistrationPage from '@/components/auth/CompleteRegistrationPage';
import ConfirmationPage from '@/components/auth/ConfirmationPage';
import PageNotFound from '@/components/shared/PageNotFound';

import { Public, RequireAuth, RequireTenant } from './lib';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <ContainedAppLayout>
      <Routes location={location}>
        <Route
          key="home"
          path="/"
          element={
            <RequireTenant>
              <LandingPage />
            </RequireTenant>
          }
        />
        <Route key="completeRegistration" path="completeRegistration" element={<CompleteRegistrationPage />} />
        <Route
          key="profile"
          path="/profile/*"
          element={
            <RequireTenant>
              <ProfilePage />
            </RequireTenant>
          }
        />
        <Route
          key="confirm"
          path="/confirm/:confirmationCode"
          element={
            <Public>
              <ConfirmationPage />
            </Public>
          }
        />
        <Route
          key="error"
          path="*"
          element={
            <Public>
              <PageNotFound />
            </Public>
          }
        />
      </Routes>
    </ContainedAppLayout>
  );
}
