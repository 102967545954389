import { Home as HomeIcon, Leaderboard as LeaderboardIcon, Chat as ChatIcon } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Container, Paper } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { hasPermission, useAuthenticatedUserStore } from '@/stores/UserStore';

import Home from './Home';
import Leaderboard from './Leaderboard';
import Suggestions from './Suggestions';

const useStyles = makeStyles()(() => ({
  nav: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
  },
}));

export default function LandingPage() {
  const [tab, setTab] = useState(0);
  const user = useAuthenticatedUserStore();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const canSeeSuggestions = useMemo(() => hasPermission('suggestion:read', user), [user]);

  return (
    <Container>
      {tab === 0 && <Home />}
      {tab === 1 && <Leaderboard />}
      {tab === 2 && <Suggestions />}
      <Paper className={classes.nav} elevation={3}>
        <BottomNavigation value={tab} onChange={(e, v) => setTab(v)} showLabels>
          <BottomNavigationAction label={t('app:home')} icon={<HomeIcon />} />
          <BottomNavigationAction label={t('app:leaderboard')} icon={<LeaderboardIcon />} />
          {!!canSeeSuggestions && <BottomNavigationAction label={t('app:suggestions')} icon={<ChatIcon />} />}
        </BottomNavigation>
      </Paper>
    </Container>
  );
}
