import Loading from '@boilerplate/components/Loading';
import { Add as AddIcon } from '@mui/icons-material';
import { Alert, IconButton, Stack, Typography } from '@mui/material';
import { map, filter } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import BingoEntryModel from '@/entities/bingoEntry/BingoEntryModel';

import BingoEntry from './BingoEntry';
import SuggestionDialog from './SuggestionDialog';

const useStyles = makeStyles()(({ spacing, breakpoints }) => ({
  container: {
    display: 'grid',
    gap: spacing(2),

    gridTemplateColumns: 'repeat(6, 1fr)',

    [breakpoints.down('xl')]: { gridTemplateColumns: 'repeat(5, 1fr)' },
    [breakpoints.down('lg')]: { gridTemplateColumns: 'repeat(4, 1fr)' },
    [breakpoints.down('md')]: { gridTemplateColumns: 'repeat(3, 1fr)' },
    [breakpoints.down('sm')]: { gridTemplateColumns: 'repeat(2, 1fr)' },
    [breakpoints.down('xs')]: { gridTemplateColumns: 'repeat(1, 1fr)' },
  },
}));

function Home(): JSX.Element {
  const [suggestionAddOpen, setSuggestionAddOpen] = useState(false);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { collection, loading, error, refetch } = BingoEntryModel.useLiveCollection({ variables: { withCompleteds: true } });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Alert severity="error">{error.message || error.toString()}</Alert>;
  }

  if (Object.keys(collection).length === 0) {
    return <Alert severity="error">{t('crud:noData')}</Alert>;
  }

  return (
    <>
      <div className={classes.container}>
        {map(collection, (entry) => {
          return <BingoEntry key={entry.id} bingoEntry={entry} refetch={refetch} />;
        })}
        <Stack alignItems="center" justifyContent="center" padding={1}>
          <IconButton onClick={() => setSuggestionAddOpen(true)} size="large">
            <AddIcon fontSize="large" />
          </IconButton>
        </Stack>
      </div>
      <SuggestionDialog open={suggestionAddOpen} onClose={() => setSuggestionAddOpen(false)} />
    </>
  );
}

export default Home;
