import { PaletteMode, ThemeOptions, createTheme } from '@mui/material';

type CreateDefaultThemeOptions = {
  mode: PaletteMode;
};

const createDefaultTheme = ({ mode }: CreateDefaultThemeOptions) => {
  const defaultTheme: ThemeOptions = {
    palette: {
      mode,
      primary: {
        main: '#038a8a',
      },
    },
    typography: {
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightMedium: 600,
      fontFamily: '"Barlow", "Helvetica", "Arial", sans-serif',
      h1: {
        fontSize: 48,
        fontWeight: 400,
        lineHeight: 1.36,
      },
      h2: {
        fontSize: 32,
        fontWeight: 400,
        lineHeight: 1.36,
      },
      h3: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: 1.36,
      },
      body1: {
        fontSize: 16,
        lineHeight: 1.36,
      },
      body2: {
        fontSize: 14,
        lineHeight: 1.36,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
          contained: {
            boxShadow: 'none',
          },
        },
      },
    },
  };

  if (mode === 'light') {
    defaultTheme.palette ??= {};
    defaultTheme.palette.background ??= {};
    defaultTheme.palette.background.default = '#f8f8f8';
  }

  return createTheme(defaultTheme);
};

export const getBrowserDefaultTheme = () => matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

export default createDefaultTheme;
