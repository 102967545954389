import app from './app.json';
import auth from './auth.json';
import crud from './crud.json';
import entities from './entities.json';
import entityFields from './entityFields';
import headerLinks from './headerLinks.json';
import menu from './menu.json';
import messages from './messages.json';
import notFound from './notFound.json';
import strings from './strings.json';
import tenants from './tenants.json';
import users from './users.json';

export default {
  app,
  auth,
  crud,
  entities,
  entityFields,
  headerLinks,
  menu,
  messages,
  notFound,
  strings,
  tenants,
  users,
};
