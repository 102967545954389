import { QueryHookOptions } from '@apollo/client';
import { keyBy, Dictionary, omit } from 'lodash';
import { useEffect, useState } from 'react';

import BingoEntryBaseModel from '@/_base/bingoEntry/BingoEntryBaseModel';
import { GetBingoEntriesQuery, GetBingoEntriesQueryVariables, useCreatedBingoEntrySubscription, useDeletedBingoEntrySubscription, useUpdatedBingoEntrySubscription } from '@/graphql';

type BingoCollection = Dictionary<GetBingoEntriesQuery['bingoEntries']['items'][number]>;

const BingoEntryModel = {
  ...BingoEntryBaseModel,
  useLiveCollection: (baseOptions?: QueryHookOptions<GetBingoEntriesQuery, GetBingoEntriesQueryVariables>) => {
    const [collection, setCollection] = useState<BingoCollection>({});

    const { items, loading, error, refetch } = BingoEntryBaseModel.useGetAll(baseOptions);

    useEffect(() => {
      if(!loading && items) {
        setCollection(keyBy(items, 'id'));
      }
    }, [items, loading]);

    

    useCreatedBingoEntrySubscription({
      variables: baseOptions?.variables,
      shouldResubscribe: true,
      fetchPolicy: 'no-cache',
      onSubscriptionData: ({ subscriptionData }) => {
        const { data } = subscriptionData;

        if(data?.createdBingoEntry?.id) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            [data.createdBingoEntry.id]: data?.createdBingoEntry,
          }))
        }

      },
    });

    useUpdatedBingoEntrySubscription({
      variables: baseOptions?.variables,
      shouldResubscribe: true,
      fetchPolicy: 'no-cache',
      onSubscriptionData: ({ subscriptionData }) => {
        const { data } = subscriptionData;

        if(data?.updatedBingoEntry?.id) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            [data.updatedBingoEntry.id]: data?.updatedBingoEntry,
          }))
        }

      },
    });
  
    useDeletedBingoEntrySubscription({
      shouldResubscribe: true,
      fetchPolicy: 'no-cache',
      onSubscriptionData: ({ subscriptionData }) => {
        const { data } = subscriptionData;

        if(data?.deletedBingoEntry?.id) {
          setCollection((prevCollection) => {
            const copyCollection = { ...prevCollection };

            return omit(copyCollection, data.deletedBingoEntry.id);
          });
        }

      },
    });


    return { collection, loading, error, refetch }
  }
};

export default BingoEntryModel;
