import { QueryResult } from '@apollo/client';
import DialogBase from '@boilerplate/components/DialogBase';
import Loading from '@boilerplate/components/Loading';
import { Alert, List, ListItem, ListItemText } from '@mui/material';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BingoEntry, GetBingoEntriesQuery } from '@/graphql';

import { FormattedUser } from './Leaderboard';

export type UserOverviewDialogProps = {
  open: boolean;
  onClose: () => void;
  user: FormattedUser | null;
  bingoEntriesQuery: QueryResult<GetBingoEntriesQuery>;
};

function Content({ bingoEntries, user }: { bingoEntries: Omit<BingoEntry, 'completeds'>[]; user: FormattedUser }) {
  const { i18n } = useTranslation();

  const data = useMemo(() => {
    return sortBy(
      bingoEntries.map((entry) => {
        const completed = user.completed.find((item) => item.bingoEntryId === entry.id);

        return { ...entry, completed: completed?.date?.toLocaleString(i18n.language) ?? false };
      }),
      (entry) => !entry.completed
    );
  }, [bingoEntries, i18n.language, user.completed]);

  return (
    <List disablePadding>
      {data.map((entry) => (
        <ListItem key={entry.id}>
          <ListItemText
            secondary={entry.completed}
            primary={<span style={{ textDecoration: entry.completed ? 'line-through' : '' }}>{entry.name}</span>}
          />
        </ListItem>
      ))}
    </List>
  );
}

function UserOverviewDialog({ open, onClose, user, bingoEntriesQuery }: UserOverviewDialogProps): JSX.Element {
  const { data, loading, error } = bingoEntriesQuery;

  const content = (() => {
    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Alert severity="error">{error.message || error.toString()}</Alert>;
    }

    if (!user) {
      return null;
    }

    return <Content bingoEntries={data?.bingoEntries.items ?? []} user={user} />;
  })();

  return (
    <DialogBase open={open} onClose={onClose} title={user?.name}>
      {content}
    </DialogBase>
  );
}

export default UserOverviewDialog;
