import React from 'react';
import { Container } from '@mui/material';
import AppLayout from './AppLayout';

type ContainedAppLayoutProps = {
  children: React.ReactNode;
};

export default function ContainedAppLayout({ children }: ContainedAppLayoutProps) {
  return (
    <AppLayout>
      <Container maxWidth="lg" sx={{ my: 2 }}>
        {children}
      </Container>
    </AppLayout>
  );
}
