import DialogBase from '@boilerplate/components/DialogBase';
import user from '@entities/user';
import { LoadingButton } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateSuggestionMutation } from '@/graphql';
import Notistack from '@/lib/notistack';
import { useAuthenticatedUserStore } from '@/stores/UserStore';

export type SuggestionDialogProps = {
  open: boolean;
  onClose: () => void;
};

function SuggestionDialog({ onClose, ...props }: SuggestionDialogProps): JSX.Element {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useAuthenticatedUserStore();
  const { t } = useTranslation();

  const [createSuggestion] = useCreateSuggestionMutation();

  const handleAdd = () => {
    setLoading(true);
    createSuggestion({
      variables: { content, userId: user.id },
    })
      .then(() => {
        setContent('');
        onClose();
      })
      .catch((err) => {
        console.error(err);
        Notistack.toast(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <DialogBase
      {...props}
      onClose={onClose}
      title={t('app:addSuggestion')}
      buttons={
        <LoadingButton variant="text" onClick={() => handleAdd()} loading={loading} disabled={!content}>
          {t('crud:add')}
        </LoadingButton>
      }
    >
      <TextField value={content} label={t('entities:suggestion')} onChange={(e) => setContent(e.target.value)} />
    </DialogBase>
  );
}

export default SuggestionDialog;
