import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetBingoEntryDocument,
  GetBingoEntryQuery,
  GetBingoEntryQueryVariables,
  GetBingoEntriesDocument,
  GetBingoEntriesQuery,
  GetBingoEntriesQueryVariables,
  useGetBingoEntryQuery,
  useGetBingoEntryLazyQuery,
  useGetBingoEntriesQuery,
  useGetBingoEntriesLazyQuery,

    useGetAllRelatedDataForBingoEntryQuery,
    useGetAllRelatedDataForBingoEntryLazyQuery,
    GetAllRelatedDataForBingoEntryQuery,
    GetAllRelatedDataForBingoEntryQueryVariables,

  CreateBingoEntryDocument,
  CreateBingoEntryMutation,
  CreateBingoEntryMutationVariables,
  useCreateBingoEntryMutation,

  DeleteBingoEntryDocument,
  DeleteBingoEntryMutation,
  DeleteBingoEntryMutationVariables,
  useDeleteBingoEntryMutation,

  UpdateBingoEntryDocument,
  UpdateBingoEntryMutation,
  UpdateBingoEntryMutationVariables,
  useUpdateBingoEntryMutation,

    useCreatedBingoEntrySubscription,
    useUpdatedBingoEntrySubscription,
    useDeletedBingoEntrySubscription,
    useRestoredBingoEntrySubscription,
} from '@/graphql';

  type BingoEntryCollection = Dictionary<NonNullable<GetBingoEntriesQuery['bingoEntries']['items']>[number]>;

const BingoEntryBaseModel = {
  get: (options: Omit<QueryOptions<GetBingoEntryQueryVariables, GetBingoEntryQuery>, 'query'>) => {
    return apolloClient.query<GetBingoEntryQuery, GetBingoEntryQueryVariables>({
      ...options,
      query: GetBingoEntryDocument,
    })
    .then(({ data }) => data.bingoEntry);
  },

  useGet: useGetBingoEntryQuery,

  getAll: (options?: Omit<QueryOptions<GetBingoEntriesQueryVariables, GetBingoEntriesQuery>, 'query'>) => {
    return apolloClient
      .query<GetBingoEntriesQuery, GetBingoEntriesQueryVariables>({
        ...options,
        query: GetBingoEntriesDocument
      })
      .then(({ data }) => data.bingoEntries.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetBingoEntriesQuery, GetBingoEntriesQueryVariables>) => {
    const hookResult = useGetBingoEntriesQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.bingoEntries?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForBingoEntryQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForBingoEntryQuery, GetAllRelatedDataForBingoEntryQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForBingoEntryQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetBingoEntryLazyQuery,

  useGetAllLazy: useGetBingoEntriesLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForBingoEntryLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateBingoEntryMutation, CreateBingoEntryMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateBingoEntryMutation, CreateBingoEntryMutationVariables>({
      ...options,
      mutation: CreateBingoEntryDocument,
    });
  },

  useCreate: useCreateBingoEntryMutation,

  update: (options: Omit<MutationOptions<UpdateBingoEntryMutation, UpdateBingoEntryMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateBingoEntryMutation, UpdateBingoEntryMutationVariables>({
      ...options,
      mutation: UpdateBingoEntryDocument,
    });
  },

  useUpdate: useUpdateBingoEntryMutation,

  delete: (options: Omit<MutationOptions<DeleteBingoEntryMutation, DeleteBingoEntryMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteBingoEntryMutation, DeleteBingoEntryMutationVariables>({
      ...options,
      mutation: DeleteBingoEntryDocument,
    });
  },

  useDelete: useDeleteBingoEntryMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetBingoEntriesQuery, GetBingoEntriesQueryVariables>) => {
      const [collection, setCollection] = useState<BingoEntryCollection>({});

      const { items, loading, error, refetch } = BingoEntryBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedBingoEntrySubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdBingoEntry?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdBingoEntry.id]: data.createdBingoEntry,
            }));
          }
        },
      });

      useUpdatedBingoEntrySubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedBingoEntry?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedBingoEntry.id]: data.updatedBingoEntry,
            }));
          }
        },
      });

      useDeletedBingoEntrySubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedBingoEntry?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedBingoEntry.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredBingoEntrySubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredBingoEntry?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredBingoEntry.id]: data.restoredBingoEntry,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default BingoEntryBaseModel;
