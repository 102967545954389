import config from '@frontend/config';
import { Typography } from '@mui/material';
import React from 'react';

import { Content } from '@boilerplate/lib/flybase/content/react';

function DefaultAuthLayoutRight() {
  return (
    <>
      <Typography variant="h2">{config.app.name}</Typography>

      <Content>auth.list</Content>
    </>
  );
}

export default DefaultAuthLayoutRight;
