import bingoEntry from './bingoEntry.json';
import completed from './completed.json';
import content from './content.json';
import contentFill from './contentFill.json';
import defaultTranslations from './default.json';
import errorLog from './errorLog.json';
import eventLog from './eventLog.json';
import job from './job.json';
import mailLog from './mailLog.json';
import mailTemplate from './mailTemplate.json';
import mailTemplateFill from './mailTemplateFill.json';
import permission from './permission.json';
import resetPassword from './resetPassword.json';
import role from './role.json';
import suggestion from './suggestion.json';
import tenant from './tenant.json';
import user from './user.json';
import userTenantRole from './userTenantRole.json';
import vote from './vote.json';

export default {
  bingoEntry,
  completed,
  content,
  contentFill,
  default: defaultTranslations,
  errorLog,
  eventLog,
  job,
  mailLog,
  mailTemplate,
  mailTemplateFill,
  permission,
  resetPassword,
  role,
  suggestion,
  tenant,
  user,
  userTenantRole,
  vote,
};
