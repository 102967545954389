import Loading from '@boilerplate/components/Loading';
import { Refresh as RefreshIcon, Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Container, IconButton, List, ListItem, ListItemText, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { Suggestion, useCreateBingoEntryMutation, useDeleteSuggestionMutation, useGetSuggestionsQuery } from '@/graphql';
import Notistack from '@/lib/notistack';

const useStyles = makeStyles()(() => ({
  refresh: {
    marginLeft: 'auto',
    display: 'flex',
  },
}));

function Suggestions(): JSX.Element {
  const { data, loading, error, refetch } = useGetSuggestionsQuery({ fetchPolicy: 'cache-first', variables: { withUser: true } });
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [deleteSuggestion] = useDeleteSuggestionMutation();
  const [createBingoEntry] = useCreateBingoEntryMutation();
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();

  const handleRefresh = () => {
    setRefetchLoading(true);
    refetch()
      .catch((err) => {
        console.error(err);
        Notistack.toast(err);
      })
      .finally(() => {
        setRefetchLoading(false);
      });
  };

  const handleApproval = (suggestion: Omit<Suggestion, 'userId' | 'user'>, approved: boolean) => {
    setApprovalLoading(true);
    deleteSuggestion({ variables: { id: suggestion.id } })
      .then(() => {
        if (!approved) {
          return undefined;
        }

        return createBingoEntry({ variables: { name: suggestion.content } });
      })
      .then(() => refetch())
      .catch((err) => {
        console.error(err);
        Notistack.toast(err);
      })
      .finally(() => setApprovalLoading(false));
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Alert severity="error">{error.message || error.toString()}</Alert>;
  }

  return (
    <Container maxWidth="sm">
      <LoadingButton
        loading={refetchLoading}
        loadingPosition="start"
        variant="outlined"
        startIcon={<RefreshIcon />}
        className={classes.refresh}
        onClick={handleRefresh}
      >
        {t('app:refresh')}
      </LoadingButton>
      {!data?.suggestions?.items || data.suggestions.items.length <= 0 ? (
        <Alert severity="error">{t('crud:noData')}</Alert>
      ) : (
        <List>
          {data.suggestions.items.map((suggestion) => (
            <ListItem key={suggestion.id}>
              <ListItemText
                primary={suggestion.content}
                secondary={
                  <Stack>
                    <span>{suggestion.user?.name || '?'}</span>
                    <span>{suggestion.createdAt ? new Date(suggestion.createdAt).toLocaleString(i18n.language) : '?'}</span>
                  </Stack>
                }
              />
              <Stack flexDirection="row" gap={1}>
                <IconButton disabled={approvalLoading} onClick={() => handleApproval(suggestion, true)}>
                  <CheckIcon />
                </IconButton>
                <IconButton disabled={approvalLoading} onClick={() => handleApproval(suggestion, false)}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </ListItem>
          ))}
        </List>
      )}
    </Container>
  );
}

export default Suggestions;
