import DialogBase from '@boilerplate/components/DialogBase';
import FormikTextField from '@boilerplate/components/FormikTextField';
import useDefaultLoginPageStyles from '@boilerplate/components/auth/useDefaultLoginPageStyles';
import { Button, Container } from '@mui/material';
import { Formik, FormikErrors, FormikHelpers, Form, FormikProps } from 'formik';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import isEmail from 'validator/es/lib/isEmail';

import config from '@/config';
import Notistack from '@/lib/notistack';
import { loginUser, resendConfirmAccount } from '@/stores/UserStore';

import AppLayout from '../app/Layout/AppLayout';
import Logo from '../shared/Logo';

interface FormValues {
  email: string;
  password: string;
  totpToken: string;
}

const initialValues: FormValues = {
  email: '',
  password: '',
  totpToken: '',
};

function DefaultLoginPage() {
  const { t } = useTranslation();
  const { classes, cx } = useDefaultLoginPageStyles();
  const formRef = useRef<FormikProps<FormValues>>();
  const [openNotActive, setOpenNotActive] = useState(false);
  const [openNotConfirmed, setOpenNotConfirmed] = useState(false);
  const [show2FA, setShow2FA] = useState(false);
  const totpInputRef = useRef<HTMLInputElement>();

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.email) {
      errors.email = t('auth:validation.required');
    } else if (!isEmail(values.email)) {
      errors.email = t('auth:validation.emailInvalid');
    }

    if (!values.password) {
      errors.password = t('auth:validation.required');
    }
  };

  const handleSubmit = (values: FormValues, { setSubmitting, setErrors }: FormikHelpers<FormValues>) => {
    loginUser(values.email, values.password, values.totpToken)
      .then(({ totpRequired }) => {
        if (totpRequired) {
          setShow2FA(true);

          requestAnimationFrame(() => {
            totpInputRef.current?.focus?.();
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message === 'auth:login.notConfirmed.error') {
          setOpenNotConfirmed(true);

          return;
        }

        if (error?.response?.data?.message === 'auth:login.notActive.error') {
          setOpenNotActive(true);

          return;
        }

        const errorMessage = error?.response?.data?.message ? t([error.response.data.message, 'auth:login.error']) : t('auth:login.error');

        console.error(error);

        setErrors({
          email: '',
          password: errorMessage,
          totpToken: errorMessage,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleCloseNotActive = useCallback(() => {
    setOpenNotActive(false);
  }, []);

  const handleCloseNotConfirmed = useCallback(() => {
    setOpenNotConfirmed(false);
  }, []);

  const handleFixNotConfirmed = useCallback(() => {
    if (formRef.current?.values.email) {
      resendConfirmAccount(formRef.current.values.email)
        .then(() => {
          Notistack.toast(t('auth:login.notConfirmed.resendSuccess'), { variant: 'success' });
        })
        .catch((error) => {
          Notistack.toast(t(error?.response?.data?.message, 'auth:login.notConfirmed.resendError'), {
            variant: 'error',
          });
        })
        .finally(() => {
          setOpenNotConfirmed(false);
        });
    }
  }, [t]);

  const backendUrl = config.app.backendUrl;

  return (
    <AppLayout>
      <Container maxWidth="sm" sx={{ marginTop: 8 }}>
        <Logo width="100%" />

        {!!config.auth.registerEnabled && (
          <div style={{ margin: '10px 0' }}>
            {!!config.auth.facebookLoginEnabled && (
              <a href={`${backendUrl}/auth/facebook`} className={cx(classes.socialButton, classes.facebook_connect)}>
                <span>{t('auth:login.facebook')}</span>
              </a>
            )}
            {!!config.auth.googleLoginEnabled && (
              <a href={`${backendUrl}/auth/google`} className={cx(classes.socialButton, classes.google_connect)}>
                <span>{t('auth:login.google')}</span>
              </a>
            )}
          </div>
        )}

        <br />

        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} innerRef={formRef}>
          {({ isSubmitting }) => (
            <Form>
              {!show2FA ? (
                <>
                  <FormikTextField type="email" name="email" label={t('auth:fields.email')} />

                  <FormikTextField type="password" name="password" label={t('auth:fields.password')} />
                </>
              ) : (
                <FormikTextField type="text" name="totpToken" label={t('auth:fields.token')} ref={totpInputRef} />
              )}

              <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary">
                {t('auth:login.submit')}
              </Button>
            </Form>
          )}
        </Formik>

        {!show2FA && (
          <>
            <p>
              <Link to="/forgot-password">{t('auth:links.forgotPassword')}</Link>
            </p>

            {!!config.auth.registerEnabled && (
              <p>
                <Link to="/register">{t('auth:links.dontHaveAnAccountYetRegister')}</Link>
              </p>
            )}
          </>
        )}

        <DialogBase
          id="not-active"
          open={openNotActive}
          onClose={handleCloseNotActive}
          title={t('auth:login.notActive.title')}
          description={t('auth:login.notActive.description')}
        />

        <DialogBase
          id="not-confirmed"
          open={openNotConfirmed}
          onClose={handleCloseNotConfirmed}
          title={t('auth:login.notConfirmed.title')}
          description={t('auth:login.notConfirmed.description')}
          buttons={
            <Button variant="text" onClick={handleFixNotConfirmed}>
              {t('auth:login.notConfirmed.requestNew')}
            </Button>
          }
        />
      </Container>
    </AppLayout>
  );
}

export default DefaultLoginPage;
