import Header from '@frontend/components/app/Layout/Header/Header';
import ConfirmationDialog from '@frontend/components/shared/ConfirmationDialog';
import { Box } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  content: {
    width: '100%',
    minHeight: '100px',
    marginTop: 64,
    display: 'flex',
    flexGrow: 1,
  },
});

type AppLayoutProps = {
  children?: React.ReactNode;
};

function AppLayout({ children }: AppLayoutProps) {
  const { classes } = useStyles();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '100%', minHeight: '100vh' }}>
      <Header />

      <div className={classes.content}>{children}</div>

      <Box sx={{ marginTop: '48px' }} />

      <ConfirmationDialog />
    </Box>
  );
}

export default AppLayout;
