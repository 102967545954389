import { Skeleton } from '@mui/material';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Sanitized from '@boilerplate/components/Sanitized';

import Flybase from '../index';

import { ContentsType, Get, ReplacementType } from './types';

import ContentClass, { useContentGroup as baseUseContentGroup } from './index';

type ContextValue = {
  contents?: ContentsType;
  content?: ContentClass;
  locale: string;
  get?: Get;
};

const Context = createContext<{
  setValue: (value: ContextValue) => void;
  value: ContextValue;
}>({
  setValue: () => {},
  value: {
    locale: 'en-US',
  },
});

let prevContent: ContentClass | null = null;

function useGetContents() {
  const { value: context, setValue: setContext } = useContext(Context);

  const { contents, get, content, locale } = context;

  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!content) {
      console.error("'flybase' prop not provided");

      return;
    }

    if (content === prevContent) {
      return;
    }

    prevContent = content;

    setLoading(true);
    setError(null);

    content
      .get()
      .then((data) => setContext({ ...context, ...data }))
      .catch((err) => {
        console.error(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setContext, content]);

  return useMemo(() => ({ contents, get, locale, loading, error }), [contents, get, locale, loading, error]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = Record<string, any>;

function SanitizedContent({
  machineName,
  props = {},
  replacements,
  component = 'div',
}: {
  machineName: string;
  replacements?: ReplacementType;
  props?: Props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
}) {
  const { get, locale, loading } = useGetContents();

  const content = useMemo(() => get?.(machineName, locale, { replacements }) ?? machineName, [get, locale, machineName, replacements]);

  if (loading) {
    const Component = component;

    return (
      <Component {...props}>
        <Skeleton>
          <p style={{ visibility: 'hidden' }} aria-hidden>
            {machineName}
          </p>
        </Skeleton>
      </Component>
    );
  }

  return (
    <Sanitized {...props} component={component}>
      {content}
    </Sanitized>
  );
}

export function useContent(replacements?: ReplacementType) {
  return useCallback(
    (machineName: string, component?: string | ReactElement, props?: Props) => (
      <SanitizedContent props={props} component={component} machineName={machineName} replacements={replacements} />
    ),
    [replacements]
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Content<T = Props>({ component, children, ...props }: { children: string; component?: any } & T) {
  const content = useContent();

  return content(children, component, props);
}

/**
 * Get all contents by a group name, and order them.
 * Groups are determined by "." characters in the machine name.
 *
 * For example: useContentGroup('faq.internetPrivacy'), given the contents:
 *
 * - faq.internetPrivacy.10.question = 'q10'
 * - faq.internetPrivacy.10.answer   = 'a10'
 * - faq.internetPrivacy.20.question = 'q20'
 * - faq.internetPrivacy.20.answer   = 'a20'
 * - faq.internetPrivacy.30.question = 'q30'
 * - faq.internetPrivacy.30.answer   = 'a30'
 *
 * will return the following array (always correctly ordered on the index).
 * result = [
 *    { index: '10', question: 'q10', answer: 'a10' },
 *    { index: '20', question: 'q20', answer: 'a20' },
 *    { index: '30', question: 'q30', answer: 'a30' },
 * ];
 */
export function useContentGroup(groupName: string) {
  const contents = useGetContents();
  const content = useContent();

  return useMemo(
    () => (contents.contents ? baseUseContentGroup(contents.contents, groupName, { map: content }) : []),
    [groupName, contents.contents, content]
  );
}

export type ContentProviderProps = {
  children: ReactNode;
  flybase: Flybase;
  locale: string;
};

/**
 * @description Tries to find the user's preferred locale by matching the values returned
 *  by `i18next-browser-languagedetector` to the supported locales.
 *  If no match can be found, this function returns `null`.
 */
export const getLocale = (supportedLocales: string[], options?: DetectorOptions): string | null => {
  const languageDetector = new LanguageDetector(null, options);
  const detected = ((result = []) => (Array.isArray(result) ? result : [result]))(languageDetector.detect());

  return supportedLocales.find((locale) => detected.includes(locale)) ?? null;
};

const usePropChange = <TState extends Record<string, unknown>, TKey extends keyof TState>(
  name: TKey,
  prop: TState[TKey],
  setter: Dispatch<SetStateAction<TState>>
) => {
  useEffect(() => {
    setter((prev) => ({ ...prev, [name]: prop }));
  }, [name, prop, setter]);
};

export function ContentProvider({ children, flybase, locale }: ContentProviderProps) {
  const [value, setValue] = useState<ContextValue>({ locale, content: flybase?.content });

  usePropChange('content', flybase?.content, setValue);
  usePropChange('locale', locale, setValue);

  const providerValue = useMemo(() => ({ value, setValue }), [value]);

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
}
