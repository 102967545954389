import { Avatar as MaterialAvatar, AvatarProps as MaterialAvatarProps } from '@mui/material';
import React from 'react';

export type AvatarProps = Omit<MaterialAvatarProps, 'src'> & {
  user: { name: string; avatar?: string | null };
};

function Avatar({ user, ...props }: AvatarProps): JSX.Element {
  return (
    <MaterialAvatar
      src={user.avatar || `https://api.dicebear.com/6.x/pixel-art/svg?seed=${user.name}`}
      alt={`${user.name} profile picture`}
      {...props}
    >
      {user.name[0] || '?'}
    </MaterialAvatar>
  );
}

export default Avatar;
