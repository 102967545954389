import { Card, CardActionArea, CardContent, CircularProgress, Typography } from '@mui/material';
import { sample } from 'lodash';
import React, { useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { BingoEntry as BingoEntryType, useCreateCompletedMutation, useDeleteCompletedMutation } from '@/graphql';
import Notistack from '@/lib/notistack';
import { useAuthenticatedUserStore } from '@/stores/UserStore';

const snouds = [
  'ah-geil',
  'dringend',
  'gewoon-praktisch',
  'goedzo',
  'hai',
  'heftig',
  'ja-echt',
  'ja',
  'jah',
  'mensen',
  'netto-6k',
  'noud',
  'oh-heerlijk',
  'oke',
  'opscheiten',
  'pedofielen-fotos',
  'prangende-vraag',
  'raggee',
  'vandaag',
  'waarvoor-waarvoor',
  'wat-zeg-je',
  'zit-hier-te-wachten',
];

export type BingoEntryProps = {
  refetch: () => Promise<void>;
  bingoEntry: BingoEntryType;
};

const useStyles = makeStyles<{ hasCompleted: boolean }>()((theme, { hasCompleted }) => ({
  actionArea: {
    height: '100%',

    '&': hasCompleted && {
      textDecoration: 'line-through',
      opacity: 0.6,
    },
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
  },
  loader: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    display: 'flex',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
  },
}));

function BingoEntry({ bingoEntry, refetch }: BingoEntryProps): JSX.Element {
  const user = useAuthenticatedUserStore();
  const [loading, setLoading] = useState(false);

  const [createCompleted] = useCreateCompletedMutation();
  const [deleteCompleted] = useDeleteCompletedMutation();

  const completed = useMemo(() => {
    if (!bingoEntry.completeds.items) {
      return false;
    }

    return bingoEntry.completeds.items.find((entry) => entry.userId === user.id);
  }, [bingoEntry.completeds.items, user.id]);

  const { classes } = useStyles({ hasCompleted: !!completed });

  const handleClick = () => {
    setLoading(true);
    const promise = completed
      ? deleteCompleted({ variables: { id: completed.id } })
      : createCompleted({ variables: { userId: user.id, bingoEntryId: bingoEntry.id } });

    new Audio(`/snoud/${sample(snouds)}.mp3`).play().catch(console.error);

    promise
      .then(refetch)
      .catch((err) => {
        console.error(err);
        Notistack.toast(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card variant="outlined" sx={{ position: 'relative' }}>
      <CardActionArea className={classes.actionArea} onClick={handleClick} disabled={loading}>
        <CardContent className={classes.content}>
          <Typography variant="h6" component="h2">
            {bingoEntry.name}
          </Typography>
        </CardContent>
      </CardActionArea>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
    </Card>
  );
}

export default BingoEntry;
