import Loading from '@boilerplate/components/Loading';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Container, List, ListItemButton, ListItemAvatar, Stack, Typography, IconButton } from '@mui/material';
import { orderBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { useGetBingoEntriesQuery, useGetUsersQuery } from '@/graphql';
import Notistack from '@/lib/notistack';

import Avatar from './Avatar';
import CrownIcon from './CrownIcon';
import UserOverviewDialog from './UserOverviewDialog';

export type FormattedUser = {
  id: string;
  name: string;
  score: number;
  avatar?: string | null;
  completed: { date: Date | null; bingoEntryId: string }[];
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  refresh: {
    marginLeft: 'auto',
    display: 'flex',
  },
  avatar: {
    boxShadow: '0px 7px 8px -4px rgb(3, 138, 138, 0.2), 0px 12px 17px 2px rgb(3, 138, 138, 0.14), 0px 5px 22px 4px rgb(3, 138, 138, 0.12)',
    backgroundColor: `${palette.background.paper} !important`,
    aspectRatio: '1/1',
    height: '100%',
    padding: 0,

    '&>div': {
      borderRadius: '100%',
      height: '100%',
      width: '100%',
    },
  },
  listItem: {
    marginBottom: spacing(2),
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '2ch calc(100% - 2ch)',
    gap: spacing(2),
  },
  listItemContent: {
    backgroundColor: palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.6) !important' : 'rgba(0, 0, 0, 0.08) !important',
    borderRadius: '12rem',
    padding: spacing(1),
    flexGrow: 1,

    paddingRight: spacing(2),
  },
}));

function Leaderboard(): JSX.Element {
  const { data, loading, error, refetch } = useGetUsersQuery({ variables: { withCompleteds: true }, fetchPolicy: 'cache-first' });
  const bingoEntriesQuery = useGetBingoEntriesQuery({ fetchPolicy: 'cache-first' });
  const [selectedUser, setSelectedUser] = useState<FormattedUser | null>(null);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const { classes } = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    refetch().catch(console.error);
  }, [refetch]);

  const [topThree, listData] = useMemo(() => {
    if (loading || !data?.users) {
      return [[], []];
    }

    const formattedData = orderBy(
      data.users.items?.map<FormattedUser>((user) => ({
        id: user.id,
        name: user.name,
        score: user.completeds?.totalCount ?? 0,
        avatar: user.avatar,
        completed:
          user.completeds?.items?.map((item) => ({
            bingoEntryId: item.bingoEntryId,
            date: item.createdAt ? new Date(item.createdAt) : null,
          })) ?? [],
      })),
      'score',
      'desc'
    );

    return [formattedData.slice(0, 3), formattedData.slice(3)];
  }, [data, loading]);

  const handleRefresh = () => {
    setRefetchLoading(true);
    refetch()
      .catch((err) => {
        console.error(err);
        Notistack.toast(err);
      })
      .finally(() => {
        setRefetchLoading(false);
      });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Alert severity="error">{error.message || error.toString()}</Alert>;
  }

  return (
    <>
      <Container maxWidth="sm">
        <LoadingButton
          loading={refetchLoading}
          loadingPosition="start"
          variant="outlined"
          startIcon={<RefreshIcon />}
          className={classes.refresh}
          onClick={handleRefresh}
        >
          {t('app:refresh')}
        </LoadingButton>
        {topThree.length <= 0 ? (
          <Typography align="center" marginTop={4}>
            {t('app:empty')}
          </Typography>
        ) : (
          <Stack flexDirection="row" alignItems="flex-end" justifyContent="center" marginBottom={6}>
            {topThree.map((item, index) => (
              <Stack key={item.id} alignItems="center" flexDirection="column" gap={1}>
                {index === 0 && <CrownIcon color="primary" fontSize="large" />}
                <IconButton
                  onClick={() => setSelectedUser(item)}
                  className={classes.avatar}
                  style={{
                    marginLeft: index === 0 ? 0 : -12,
                    zIndex: topThree.length - index,
                    width: 120 - index * 12,
                  }}
                >
                  <Avatar user={item} />
                </IconButton>
                <Typography variant="body1" maxWidth="10ch" align="center" overflow="hidden" textOverflow="ellipsis" noWrap>
                  {item.name}
                </Typography>
                <Typography color="primary" fontSize="2rem" fontWeight="bold">
                  {item.score}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
        {listData.length > 0 && (
          <List>
            {listData.map((item, index) => (
              <li key={item.id} className={classes.listItem}>
                <Typography>{index + 4}.</Typography>
                <ListItemButton onClick={() => setSelectedUser(item)} className={classes.listItemContent}>
                  <ListItemAvatar>
                    <Avatar user={item} />
                  </ListItemAvatar>
                  <Stack gap={1} justifyContent="space-between" alignItems="center" flexDirection="row" overflow="hidden" width="100%">
                    <Typography variant="body1" overflow="hidden" textOverflow="ellipsis" noWrap>
                      {item.name}
                    </Typography>
                    <Typography color="primary" fontWeight="bold">
                      {item.score}
                    </Typography>
                  </Stack>
                </ListItemButton>
              </li>
            ))}
          </List>
        )}
      </Container>
      <UserOverviewDialog
        user={selectedUser}
        open={!!selectedUser}
        bingoEntriesQuery={bingoEntriesQuery}
        onClose={() => setSelectedUser(null)}
      />
    </>
  );
}

export default Leaderboard;
